import React, {Component} from 'react';
import moment from 'moment';

class Clock extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.update = this.update.bind(this);
    }

    update() {
        const now = moment();
        this.setState({
            date: now.format('M/D/YYYY'),
            time: now.format('h:mm:ss A')
        });
    }

    componentDidMount() {
        this.update();
        this.setState({
            timer: setInterval(this.update, 1000)
        });
    }

    componentWillUnmount() {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
    }

    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 48
            }}>
                <p style={{
                    fontSize: '2em',
                    fontWeight: 300,
                    marginBottom: 0
                }}>
                    {this.state.date}
                </p>
                <p style={{
                    fontSize: '6em',
                    fontWeight: 900,
                    marginBottom: 0
                }}>
                    {this.state.time}
                </p>
            </div>
        );
    }
}

export default Clock;

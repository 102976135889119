import {GenericAPI} from "./generic-api-client";
import {API} from "../../utils/api";

export default GenericAPI("timesheets", path => {
    return {
        get(username, date, params) {
            return API.get(`${path}/${username}/${date}`, {params});
        },
        update(username, date, data, params) {
            return API.put(`${path}/${username}/${date}`, {...data, params});
        },
        delete(username, date, params) {
            return API.delete(`${path}/${username}/${date}`, {params});
        },
        download(params) {
            return API.get(`${path}/download`, {params});
        },
        punchIn(username, key) {
            return API.post(path, {mode: 'in', username, key});
        },
        punchOut(username, key) {
            return API.post(path, {mode: 'out', username, key});
        }
    }
});

import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './App.less';
import * as Pages from './pages';
import {ProtectedRoute} from './utils';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Router>
                    {/*<div style={{*/}
                    {/*    color: 'white',*/}
                    {/*    backgroundColor: 'red',*/}
                    {/*    padding: 8,*/}
                    {/*    textAlign: 'center',*/}
                    {/*}}>*/}
                    {/*    This application is in testing stage. Any data you've entered in to the system will be removed before roll out.*/}
                    {/*</div>*/}
                    <Switch>
                        <Route exact path="/login" component={Pages.LoginPage}/>
                        <ProtectedRoute exact path="/" component={Pages.HomePage}/>
                        <ProtectedRoute exact path="/timesheets" role='admin' component={Pages.TimesheetsPage}/>
                        <ProtectedRoute exact path="/users" role='admin' component={Pages.UsersPage}/>
                        <Pages.NotFoundPage/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;

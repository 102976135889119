export default {
    api: {
        url: "https://ig2xmjvqkl.execute-api.us-east-1.amazonaws.com/production"
    },
    authentication: {
        region: 'us-east-1',
        poolId: 'us-east-1_MMFCSJVxK',
        clientId: '7a3jobd4oec8fa86m22rfolroa'
    }
};

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Col, Icon, Layout, Menu, notification} from 'antd';
import {Container} from "../components";
import {Authentication, ShowErrorNotification} from '../utils';

class AuthenticatedLayout extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.logout = this.logout.bind(this);
    }

    handleClick(e) {
        let url = e.keyPath.reduce((result, current) => {
            result = `/${current}${result}`;
            return result;
        }, '');
        if (url) {
            this.props.history.push(url);
        }
    }

    logout() {
        Authentication.logout()
            .then(() => {
                notification.success({
                    message: 'Kullanıcı çıkışı yapıldı',
                    description: 'Tekrar giriş yapabilir veya bu sayfayı kapatabilirsiniz.'
                });
                setTimeout(() => {
                    this.props.history.push('/login');
                }, 100);
            })
            .catch(err => ShowErrorNotification('Kullanıcı çıkışı yapılamadı', err))
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Authentication.getCurrentUser()
            .then(user => this.setState({user}))
            .catch(err => {
                if (err !== 'not authenticated') {
                    console.log('No current user.');
                } else {
                    ShowErrorNotification('Kullanıcı bilgileri alınamadı', err);
                }
            });
    }

    render() {
        return (
            <Layout className="default-layout">
                <Layout.Header>
                    <Container>
                        <Menu
                            mode="horizontal"
                            selectable="false"
                            style={{
                                lineHeight: '64px',
                                borderBottomWidth: '0px!important'
                            }}
                        >
                            <Menu.Item>
                                <Link to="/" onClick={e => e.stopPropagation()}>
                                    <img style={{height: 48}} src={require('../assets/images/logo_header.png')} alt="logo"/>
                                </Link>
                            </Menu.Item>
                            <Menu.SubMenu
                                style={{float: 'right'}}
                                title={(
                                    <div>
                                        <Icon type="user"/>
                                        {this.state.user?.username}
                                    </div>
                                )}
                            >
                                {this.state.user?.username === 'admin' && (
                                    <Menu.Item>
                                        <Link to="/timesheets">
                                            <Icon type="calendar"/>
                                            Timesheets
                                        </Link>
                                    </Menu.Item>
                                )}
                                {this.state.user?.username === 'admin' && (
                                    <Menu.Item>
                                        <Link to="/users">
                                            <Icon type="setting"/>
                                            Users
                                        </Link>
                                    </Menu.Item>
                                )}
                                <Menu.Item onClick={this.logout}>
                                    <Icon type="logout"/>
                                    Logout
                                </Menu.Item>
                            </Menu.SubMenu>

                        </Menu>
                    </Container>
                </Layout.Header>
                <Layout>
                    <Layout.Content>
                        {this.props.children}
                    </Layout.Content>
                    <Layout.Footer>
                        <Col align="center">
                            Made with <span role='img'>❤</span>️ by <a href="http://www.asyasoft.net" target="_blank" rel="noopener noreferrer">Asyasoft</a>
                        </Col>
                    </Layout.Footer>
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(AuthenticatedLayout);

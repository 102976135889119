import React, {Component} from 'react';
import {Button, Col, Form, Icon, Input, Row, Table} from 'antd';
import {AuthenticatedLayout} from "../../layouts";
import {Container} from "../../components";
import {ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import data from '../../data';

class UsersPage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.renderSearchForm = this.renderSearchForm.bind(this);
    }

    loadData(filter) {
        this.setState({loading: true});
        data.allUsers(filter)
            .then(users => this.setState({users, filter}))
            .catch(err => ShowErrorNotification('Error loading users', err))
            .finally(() => this.setState({loading: false}));
    }

    handleSearch(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification(err);
            }
            this.loadData(values);
        });
    }

    openCreateModal() {
        this.setState({createModalVisible: true});
    }

    closeCreateModal(record) {
        this.setState({createModalVisible: false});
        if (record) this.loadData(this.state.filter);
    }

    openEditModal(record) {
        this.setState({record, editModalVisible: true});
    }

    closeEditModal(record) {
        this.setState({record: undefined, editModalVisible: false});
        if (record) this.loadData(this.state.filter);
    }

    renderSearchForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSearch}>
                <Row type="flex" gutter={16}>
                    <Col>
                        <Form.Item label="Username">
                            {getFieldDecorator('username')(
                                <Input autoFocus prefix={<Icon type="search"/>}/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type="flex"
                    gutter={[8,8]}
                    style={{marginBottom: 8}}
                >
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon="search"
                            loading={this.state.loading}
                        >
                            Search
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            icon="plus"
                            onClick={this.openCreateModal}
                        >
                            New User
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    componentDidMount() {
        this.loadData(this.state.filter);
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Kullanıcılar</h1>
                        {this.renderSearchForm()}
                        <Table
                            rowKey="username"
                            loading={this.state.loading}
                            columns={columns}
                            dataSource={this.state.users}
                            pagination={{style: {marginRight: 16}}}
                            onRow={record => ({
                                style: {cursor: 'pointer'},
                                onClick: () => this.openEditModal(record)
                            })}
                        />
                        <CreateModal
                            visible={this.state.createModalVisible}
                            onClose={this.closeCreateModal}
                        />
                        <EditModal
                            visible={this.state.editModalVisible}
                            record={this.state.record}
                            onClose={this.closeEditModal}
                        />
                    </div>
                </Container>
            </AuthenticatedLayout>
        );
    }
}

const columns = [{
    dataIndex: 'enabled',
    key: 'enabled',
    width: 16,
    render: (text, record) => (
        <Icon
            type={record.enabled ? "check-circle" : "close-circle"}
            style={{color: record.enabled ? 'green' : 'red'}}
        />
    )
}, {
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
}];


export default Form.create()(UsersPage);

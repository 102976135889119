import * as API from './api';

class DataProvider {
    /**
     * Timesheets
     */
    allTimesheets(filter) {
        return API.TimesheetsService.all(filter);
    }
    listTimesheets(filter) {
        return API.TimesheetsService.list(filter);
    }
    getTimesheet(username, date) {
        return API.TimesheetsService.get(username, date);
    }
    createTimesheet(data) {
        return API.TimesheetsService.create(data);
    }
    updateTimesheet(username, date, data) {
        return API.TimesheetsService.update(username, date, data);
    }
    deleteTimesheet(username, date) {
        return API.TimesheetsService.delete(username, date);
    }
    downloadTimesheets(filter) {
        return API.TimesheetsService.download(filter);
    }
    punchIn(username, key) {
        return API.TimesheetsService.punchIn(username, key);
    }
    punchOut(username, key) {
        return API.TimesheetsService.punchOut(username, key);
    }

    /**
     * Users
     */
    allUsers(filter) {
        return API.UsersService.all(filter);
    }
    listUsers(filter) {
        return API.UsersService.list(filter);
    }
    getUser(username) {
        return API.UsersService.get(username);
    }
    createUser(data) {
        return API.UsersService.create(data);
    }
    updateUser(username, data) {
        return API.UsersService.update(username, data);
    }
    deleteUser(username) {
        return API.UsersService.delete(username);
    }
}

export default new DataProvider();

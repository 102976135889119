import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Result} from "antd";
import {DefaultLayout} from "../../layouts";
import {Container} from "../../components";

class AccessDeniedPage extends Component {

    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <DefaultLayout>
                <Container>
                    <div className="page">
                        <Result
                            status="403"
                            title="403"
                            subTitle="Sorry, you don't have permission to view this page."
                            extra={<Button type="primary" onClick={this.goBack}>Go back to previous page</Button>}
                        />
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default withRouter(AccessDeniedPage);

import React, {Component} from 'react';
import {Button, Col, Icon, Modal, Row} from "antd";
import EnterKeyModal from "./EnterKeyModal";
import {ShowErrorNotification} from "../../utils";
import data from '../../data';

class SelectUserModal extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openKeyModal = this.openKeyModal.bind(this);
        this.closeKeyModal = this.closeKeyModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    loadData() {
        this.setState({users: undefined, loading: true});
        data.allUsers({enabled: true})
            .then(users => this.setState({users}))
            .catch(err => ShowErrorNotification('Error loading users', err))
            .finally(() => this.setState({loading: false}));
    }

    showModal() {
        this.setState({visible: true});
        this.loadData();
    }

    closeModal(user) {
        this.setState({visible: false}, () => {
            if (this.props.onClose) {
                this.props.onClose(user);
            }
        });
    }

    openKeyModal(user) {
        this.setState({user, keyModalVisible: true});
    }

    closeKeyModal(user) {
        this.setState({keyModalVisible: false}, () => {
            if (user) {
                this.closeModal(user);
            }
        })
    }

    handleCancel() {
        this.closeModal();
    }

    handleSelect(user) {
        this.closeModal(user);
    }

    componentDidMount() {
        if (this.props.visible) {
            this.showModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== true && this.props.visible) {
            this.showModal();
        }
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                title="Select User"
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Close
                    </Button>
                ]}
                destroyOnClose={true}
            >
                {this.state.loading && (
                    <React.Fragment>
                        <Icon spin type="sync" style={{marginRight: 5}}/>
                        Please wait...
                        <br/>
                        <br/>
                    </React.Fragment>
                )}
                {this.state.users && (
                    <Row type="flex" gutter={[16,16]}>
                        {this.state.users.map(user => (
                            <Col
                                key={user.username}
                                span={8}
                            >
                                <Button
                                    block
                                    type="primary"
                                    onClick={() => this.openKeyModal(user)}
                                >
                                    {user.username}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                )}

                <EnterKeyModal
                    visible={this.state.keyModalVisible}
                    user={this.state.user}
                    punchMode={this.props.punchMode}
                    onClose={this.closeKeyModal}
                />
            </Modal>
        );
    }
}

export default SelectUserModal;

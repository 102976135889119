import React from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Layout} from 'antd';
import {Authentication, ShowErrorNotification} from '../utils';

class DefaultLayout extends React.Component {
    state = {};

    componentDidMount() {
        window.scrollTo(0, 0);
        Authentication.getCurrentUser()
            .then(user => this.setState({user}))
            .catch(err => {
                console.log("Error getting current user.", err);
                if (err !== 'not authenticated') {
                    ShowErrorNotification('Error getting current user', err);
                }
            });
    }

    render() {
        return (
            <Layout className="default-layout">
                <Layout.Content style={{padding: 64}}>
                    {this.props.children}
                </Layout.Content>
                <Layout.Footer>
                    <Col align="center">
                        Made with <span role='img'>❤</span>️ by <a href="http://www.asyasoft.net" target="_blank" rel="noopener noreferrer">Asyasoft</a>
                    </Col>
                </Layout.Footer>
            </Layout>
        );
    }
}

export default withRouter(DefaultLayout);

import React, {Component} from 'react';
import {DatePicker, Button, Col, Form, Icon, Input, Modal, Row, Table} from 'antd';
import {AuthenticatedLayout} from "../../layouts";
import {Container} from "../../components";
import {ShowErrorNotification, ShowValidationErrorNotification} from "../../utils";
import EditModal from "./EditModal";
import data from '../../data';
import moment from 'moment';

class TimesheetsPage extends Component {
    state = {
        filter: {
            firstDate: moment().format('YYYY-MM-DD'),
            lastDate: moment().format('YYYY-MM-DD')
        }
    };

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.renderSearchForm = this.renderSearchForm.bind(this);
    }

    loadData(filter) {
        this.setState({loading: true});
        data.allTimesheets(filter)
            .then(timesheets => {
                let users = [...new Set(timesheets.map(t => t.username))];
                users.sort((a, b) => {
                    if (a > b) return 1;
                    if (a < b) return -1;
                    return 0;
                });
                let result = [];
                users.forEach(username => {
                    let row = {username, total: 0};
                    row.data = timesheets.filter(t => t.username === username);
                    row.data.forEach(data => {
                        const time_in = moment(data.punch_in_time, 'h:mm A');
                        const time_out = moment(data.punch_out_time, 'h:mm A');
                        const duration = Math.round(100*time_out.diff(time_in, 'hour', true))/100;
                        if (!isNaN(duration)) {
                            data.duration = `${duration.toFixed(2)} hours`;
                            row.total += duration;
                        }
                    });
                    if (row.total >= 0) {
                        row.total = `${row.total.toFixed(2)} hours`
                    }
                    result.push(row);
                });
                this.setState({
                    timesheets: result,
                    filter
                });
            })
            .catch(err => ShowErrorNotification('Error loading timesheets', err))
            .finally(() => this.setState({loading: false}));
    }

    downloadSpreadsheet() {
        this.setState({downloading: true});
        // this.props.form.validateFieldsAndScroll((err, values) => {
        //     if (err) {
        //         return ShowValidationErrorNotification(err);
        //     }
        //
        //     this.setState({downloading: true});
        //     data.downloadTimesheets(moment(values.date).format('YYYY-MM'))
        //         .then(spreadsheet => {
        //             Modal.success({
        //                 title: 'Your Download is Ready',
        //                 content: (
        //                     <p>Please <a href={spreadsheet.url} target="_blank" rel="noopener noreferrer">click here</a> to download.</p>
        //                 )
        //             });
        //         })
        //         .catch(err => ShowErrorNotification('Error preparing download', err))
        //         .finally(() => this.setState({downloading: false}));
        // });
        data.downloadTimesheets(this.state.filter)
            .then(spreadsheet => {
                Modal.success({
                    title: 'Your Download is Ready',
                    content: (
                        <p>Please <a href={spreadsheet.url} target="_blank" rel="noopener noreferrer">click here</a> to download.</p>
                    )
                });
            })
            .catch(err => ShowErrorNotification('Error preparing download', err))
            .finally(() => this.setState({downloading: false}));
    }

    handleSearch(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification(err);
            }
            const {date, ...rest} = values;
            let filter = {...rest};
            if (date) {
                const [firstDate, lastDate] = date;
                filter.firstDate = moment(firstDate).format('YYYY-MM-DD');
                filter.lastDate = moment(lastDate).format('YYYY-MM-DD');
            }
            this.loadData(filter);
        });
    }

    openEditModal(record) {
        this.setState({record, editModalVisible: true});
    }

    closeEditModal(record) {
        this.setState({record: undefined, editModalVisible: false});
        if (record) this.loadData(this.state.filter);
    }

    renderSearchForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSearch}>
                <Row type="flex" gutter={16}>
                    <Col>
                        <Form.Item label="Date">
                            {getFieldDecorator('date', {
                                initialValue: [
                                    moment(this.state.filter.firstDate, 'YYYY-MM-DD'),
                                    moment(this.state.filter.lastDate, 'YYYY-MM-DD')
                                ],
                                rules: [{ type: 'array', required: true, message: 'Please select dates!' }]
                            })(<DatePicker.RangePicker autoFocus allowClear={false} format="M/D/YYYY"/>)}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Username">
                            {getFieldDecorator('username')(
                                <Input prefix={<Icon type="search"/>}/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type="flex"
                    gutter={[8,8]}
                    style={{marginBottom: 8}}
                >
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon="search"
                            loading={this.state.loading}
                        >
                            Search
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            icon="file-excel"
                            loading={this.state.downloading}
                            onClick={this.downloadSpreadsheet}
                        >
                            Download
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    componentDidMount() {
        this.loadData(this.state.filter);
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Timesheets</h1>
                        {this.renderSearchForm()}
                        <Table
                            rowKey={record => record.username}
                            loading={this.state.loading}
                            columns={columns}
                            dataSource={this.state.timesheets}
                            // expandedRowKeys={this.state.timesheets?.map(t => t.username)}
                            expandedRowRender={e => (
                                <Table
                                    rowKey={record => `${record.username}/${record.date}`}
                                    columns={detailColumns}
                                    dataSource={e.data}
                                    pagination={false}
                                    onRow={record => ({
                                        style: {cursor: 'pointer'},
                                        onClick: () => this.openEditModal(record)
                                    })}
                                />
                            )}
                            // pagination={{style: {marginRight: 16}}}
                            pagination={false}
                        />
                        <EditModal
                            visible={this.state.editModalVisible}
                            record={this.state.record}
                            onClose={this.closeEditModal}
                        />
                    </div>
                </Container>
            </AuthenticatedLayout>
        );
    }
}

const columns = [{
    title: 'Username',
    dataIndex: 'username',
    key: 'username'
}, {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (text, record) => <span style={{fontWeight: 'bold'}}>{record.total}</span>
}];

const detailColumns = [{
    dataIndex: 'enabled',
    key: 'enabled',
    width: 16,
    render: (text, record) => (
        <Icon type={record.enabled ? "check-circle" : "close-circle"}
              style={{color: record.enabled ? "green" : "red"}}/>
    )
}, {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text, record) => <span>{moment(record.date).format('M/D/YYYY')}</span>
}, {
    title: 'Punch-In Time',
    dataIndex: 'punch_in_time',
    key: 'punch_in_time'
}, {
    title: 'Punch-Out Time',
    dataIndex: 'punch_out_time',
    key: 'punch_out_time'
}, {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration'
}];

export default Form.create()(TimesheetsPage);

import React, {Component} from 'react';
import {AuthenticatedLayout} from "../../layouts";
import {Container, Clock} from "../../components";
import {Button, Col, Row} from "antd";
import SelectUserModal from './SelectUserModal';

class HomePage extends Component {
    state = {
        loading: false,
        modalVisible: false
    };

    constructor(props) {
        super(props);
        this.openPunchInModal = this.openPunchInModal.bind(this);
        this.openPunchOutModal = this.openPunchOutModal.bind(this);
        this.closePunchModal = this.closePunchModal.bind(this);
    }

    openPunchInModal() {
        this.setState({punchMode: 'in', punchModalVisible: true});
    }

    openPunchOutModal() {
        this.setState({punchMode: 'out', punchModalVisible: true});
    }

    closePunchModal() {
        this.setState({punchMode: undefined, punchModalVisible: false});
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">ARL Timesheet Tracker</h1>
                        <Clock/>
                        <Row
                            type="flex"
                            justify="center"
                            gutter={[8,8]}
                            style={{marginBottom: 48}}
                        >
                            <Col>
                                <Button
                                    type="primary"
                                    size="large"
                                    icon="check"
                                    onClick={this.openPunchInModal}
                                >
                                    Punch In
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type="danger"
                                    size="large"
                                    icon="close"
                                    onClick={this.openPunchOutModal}
                                >
                                    Punch Out
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <SelectUserModal
                    punchMode={this.state.punchMode}
                    visible={this.state.punchModalVisible}
                    onClose={this.closePunchModal}
                />
            </AuthenticatedLayout>
        )
    }
}

export default HomePage;

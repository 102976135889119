import React, {Component} from 'react';
import {Button, Col, Form, Input, Modal, notification, Row} from "antd";
import {ShowErrorNotification, ShowValidationErrorNotification} from "../../utils";
import data from '../../data';
import Webcam from "react-webcam";

class EnterKeyModal extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    showModal() {
        this.setState({visible: true});
    }

    closeModal(user) {
        this.setState({visible: false}, () => {
            if (this.props.onClose) {
                this.props.onClose(user);
            }
        });
    }

    handleCancel() {
        this.closeModal();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return ShowValidationErrorNotification(err);
            }
            const op = this.props.punchMode === 'out' ? data.punchOut : data.punchIn;
            this.setState({loading: true});
            op(this.props.user.username, values.key)
                .then(() => {
                    notification.success({
                        message: `User punched ${this.props.punchMode} successfully.`,
                        description: 'Thank you!'
                    });
                    this.closeModal(true);
                })
                .catch(err => ShowErrorNotification(`Error punching ${this.props.punchMode}`, err))
                .finally(() => this.setState({loading: false}));
        });
    }

    componentDidMount() {
        if (this.props.visible) {
            this.showModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== true && this.props.visible) {
            this.showModal();
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Modal
                visible={this.state.visible}
                title={this.props.punchMode === 'out' ? 'Punch Out' : 'Punch In'}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Close
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon="check"
                        loading={this.state.loading}
                        onClick={this.handleSubmit}
                    >
                        {this.props.punchMode === 'out' ? 'Punch Out' : 'Punch In'}
                    </Button>
                ]}
                destroyOnClose={true}
            >
                <Row type="flex" justify="center" align="center">
                    <Col span={12}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'stretch',
                            alignItems: 'flex-start',
                            border: '-1px dashed lightgray',
                            borderRadius: 8,
                            marginRight: 48,
                            overflow: 'hidden',
                            backgroundColor: 'whitesmoke'
                        }}>
                            <Webcam
                                mirrored
                                audio={false}
                                // ref={webcamRef}
                                width={200}
                                height={200}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    width: 200,
                                    height: 200,
                                    facingMode: 'user'
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item label="Username">
                                <Input disabled value={this.props.user?.username} />
                            </Form.Item>
                            <Form.Item label="Key Code">
                                {getFieldDecorator('key', {
                                    rules: [{required: true, message: 'Key is required'}]
                                })(<Input.Password autoFocus />)}
                            </Form.Item>
                            <Button hidden htmlType="submit"/>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default Form.create()(EnterKeyModal);

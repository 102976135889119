import {notification} from 'antd';
import CustomError from './CustomError';

const DefaultErrorMessage = 'An unexpected error occurred. Please try again later.';

export default function(message, err) {
    console.error(message, err);
    let description = undefined;
    if (err instanceof CustomError) {
        description = err.message || DefaultErrorMessage;
    } else if (typeof(err) === 'string') {
        description = err;
    }
    notification.error({message, description});
}

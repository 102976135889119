import {API} from "../../utils";

export function GenericAPI(path, callback) {
    const extras = callback ? callback(path) : undefined;
    return {
        list(params) {
            return API.list(path, {params});
        },
        all(params) {
            return API.all(path, {params});
        },
        get(id, params) {
            return API.get(id !== null ? `${path}/${id}` : path, {params});
        },
        create(data, params) {
            return API.post(path, {...data, params});
        },
        update(id, data, params) {
            return API.put(id !== null ? `${path}/${id}` : path, {...data, params});
        },
        delete(id, params) {
            return API.delete(id !== null ? `${path}/${id}` : path, {params});
        },
        ...extras
    }
}
